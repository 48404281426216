<template>
  <div class="container">
    <img src="../../assets/jd2021_t.jpg" alt="" class="activity_bg" />
    <img src="../../assets/jd2021_t.png" alt="" class="activity_bg" />
    <div class="rule">
      <div>
        <p v-html="rule" style="white-space: pre-wrap"></p>
        <!-- <p class="title">1月份【京东app拉新-翻红馆】结算规则：</p>
        <p>1.提前结算，提现扣税10%，12元/单；</p>
        <p>2.次月结算，提现扣税5%，12元/单。</p>
        <p></p>
        <p class="title" style="margin-top: 0.27rem !important">活动规则：</p>
        <p>
          1.活动期间，在快手、抖音平台售卖本专区商品，下单用户是第一次在京东下单的为“新人订单”。
        </p>
        <p>2.售卖时必须绑定翻红馆app分配的京东pid，否则无法获得收益</p>
        <p>3.若订单为非新人订单，则订单收益按实际利润率计算</p>
        <p>
          4.今日新人订单数有15-20%误差，实际以页面显示的总新人收入为准，误差属于京东官方判定筛选的不合规定的订单，翻红馆app绝对不会有扣除任何订单的行为。
        </p>
        <p>
          5.如果新人订单出现取消或退货，及违规下单等情况，则该新人订单无收益
        </p>
        <p>6.活动解释权归翻红馆及京东所有.</p>
        <p>
          7.严禁冒用京东名义进行虚假宣传，禁止任何形式的免单宣传一经发现取消活动资格。
        </p> -->
      </div>
      <!-- <p>规<br />则</p> -->
    </div>
    <div class="product">
      <img src="../../assets/jd2021_p.png" alt="" />
      <div class="product_content">
        <div
          class="item copy"
          v-for="item in list"
          :key="item.goods_link"
          @click="copy"
          slot="content"
          :data-clipboard-text="item.goods_link"
        >
          <img :src="item.goods_picture" alt="" />
          <div class="item_con">
            <div class="title">
              <span>京东</span>
              <p>{{ item.goods_name }}</p>
            </div>
            <p class="old">
              原价 <span>￥{{ item.original_price }}</span>
            </p>
            <p class="profit">新人利润:<span>12元</span></p>
            <p class="new">
              新人价<span>￥{{ item.newcomer_price }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <div class="btn_box">
      <div class="btn" @click="center">查 看 推 广 数据</div>
    </div>
    <div class="showToast" v-if="showToast">
      <div class="showToast_con">{{ showToast }}</div>
    </div>
    <!-- <div class="icon" @click="changevideoShow(true)">
      <img src="../../assets/jd2021_icon.png" alt="" />
    </div> -->
    <div class="video" v-if="videoShow">
      <div class="bg" @click="changevideoShow(false)"></div>
      <video
        src="https://fanhongguan.oss-cn-beijing.aliyuncs.com/three/2021/1614582502602_31143_2.mp4"
        controls
      ></video>
    </div>
  </div>
</template>

<script>
// import 'lib-flexible'

import { resetRem } from "@/assets/rem.js";
export default {
  data() {
    return {
      showToast: "",
      videoShow: false,
      list: [],
      rule: "",
    };
  },
  created() {
    document.title = "京东拉新";
    resetRem();
  },
  mounted() {
    this.getList();
  },
  methods: {
    changevideoShow(videoShow) {
      console.log(videoShow);
      this.videoShow = videoShow;
    },
    getList() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios.get("/app/systemConfig/get/jdreward20211106").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          console.log(res);
          var list = res.data.data.goods_list;
          that.list = list;
          that.rule = res.data.data.rule_description;
        }
      });
    },
    center() {
      var that = this;
      that.$router.push({
        path: "/h5/jd2021Center",
      });
    },
    copy() {
      var that = this;
      var clipboard = new this.Clipboard(".copy");
      clipboard.on("success", () => {
        that.showToast = "链接复制成功";
        setTimeout(function () {
          that.showToast = "";
        }, 2000);
        clipboard.destroy();
      });
      // 浏览器不支持
      clipboard.on("error", () => {
        that.showToast = "不支持";
        setTimeout(function () {
          that.showToast = "";
        }, 2000);
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>
<style scoped>
.container {
  width: 10rem;
  margin: 0 auto;
  min-height: 100vh;
  height: auto;
  position: relative;
  background: #ff6b01;
  padding-bottom: 2.4rem;
}
img {
  width: 10rem;
}
.rule {
  width: 8.8rem;
  margin: 0.293rem auto 0;
  position: relative;
}
.rule div {
  width: 8.8rem;
  background: #ff862d;
  border: 2px solid #f5c405;
  border-radius: 0.38rem;
  padding: 0.27rem 0.32rem 0.27rem 0.49rem;
  box-sizing: border-box;
  /* margin-left: 0.37rem; */
  font-size: 16px;
}
.rule div p {
  color: #f6faec;
  font-size: 14px;
  margin: 0;
  line-height: 0.4267rem;
}
.rule > p {
  width: 0.64rem;
  position: absolute;
  border: 2px solid #fcde98;
  color: #fce5ba;
  text-align: center;
  border-radius: 0.14rem;
  background: linear-gradient(to right, #e95b20, #e84d23);
  font-size: 16px;
  padding: 0.06rem 0;
  top: 20%;
}
.product {
  margin-top: 0.173rem;
}
.product_content {
  width: 8.8rem;
  margin: 0 auto;
  box-sizing: border-box;
  border: 2px solid #f5c405;
  padding: 10px;
  border-radius: 0.38rem;
  margin-top: -0.06rem;
  position: relative;
  background: #ff862d;
}
.item {
  background: #fff;
  border-radius: 0.14rem;
  /* display: flex;
  justify-content: flex-start; */
  padding: 0.2rem 0.133rem 0.266rem;
  margin-top: 0.133rem;
  box-sizing: border-box;
  width: auto;
  overflow: hidden;
}
.item:first-child {
  margin-top: 0;
}
.item img {
  width: 2.66rem;
  height: 2.66rem;
  margin-right: 0.266rem;
  float: left;
}
.item_con {
  width: 4.9rem;
  float: left;
  color: #000;
}
.title {
  width: 100%;
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0.266rem;
  /* display: flex;
  justify-content: flex-start; */
  height: 0.6rem;
}
.title span {
  display: inline-block;
  width: 1.2rem;
  height: 0.6rem;
  background: linear-gradient(to right, #ff4c30, #fb8139);
  border-radius: 0.14rem;
  text-align: center;
  color: #f2ffff;
  line-height: 0.6rem;
  margin-right: 0.133rem;
  font-size: 16px;
}
.title p {
  display: inline-block;
  line-height: 0.6rem;
  height: 0.6rem;
  font-size: 16px;
}
.old {
  color: #7e7e7e;
  margin-bottom: 0;
  /* margin-bottom: 0.09rem; */
  font-size: 14px;
}
.old span {
  padding-left: 0.133rem;
  font-size: 14px;
  text-decoration: line-through;
}
.new {
  margin: 0;
  font-size: 14px;
}
.new span {
  font-size: 16px;
  font-weight: bold;
  padding-left: 0.133rem;
}
.profit {
  margin: 0;
  font-size: 14px;
  color: #fd463c;
  text-align: right;
  margin-bottom: 0.09rem;
}
.profit span {
  font-size: 16px;
  font-weight: bold;
  padding-left: 0.133rem;
  color: #ef4e2f;
}
.showToast {
  position: fixed;
  width: 10rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  top: 0;
}
.showToast_con {
  height: 1.333rem;
  line-height: 1.333rem;
  background: #4c4c4c;
  border-radius: 0.667rem;
  padding: 0 1.28rem;
  font-family: PingFang-SC-Regular;
  font-size: 0.3733rem;
  color: #ffffff;
  letter-spacing: 0;
}
.btn_box {
  position: fixed;
  bottom: 0.6rem;
  width: 100%;
}
.btn {
  width: 6rem;
  background: #ff6b01;
  /* position: fixed;
  bottom: 0.6rem;
  left: 2rem; */
  height: 1.2rem;
  border-radius: 0.14rem;
  border: 4px solid #f5c405;
  box-sizing: border-box;
  line-height: 0.95rem;
  color: #fff;
  font-size: 0.45rem;
  text-align: center;
  margin-left: 2rem;
}
.icon {
  position: fixed;
  right: 0;
  bottom: 3rem;
  width: 100%;
}
.icon img {
  width: 1.71rem;
  float: right;
}
.video {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.video .bg {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
.video video {
  width: 92%;
  z-index: 10;
}
</style>